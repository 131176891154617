import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Nos services | Services à domicile Rivoxil Designs</title>
        <meta name={"description"} content={"Envisager. Créer. Apprécier."} />
        <meta
          property={"og:title"}
          content={"Nos services | Services à domicile Rivoxil Designs"}
        />
        <meta
          property={"og:description"}
          content={"Envisager. Créer. Apprécier."}
        />
        <meta
          property={"og:image"}
          content={"https://rivoxil.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://rivoxil.com/img/48511.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://rivoxil.com/img/48511.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://rivoxil.com/img/48511.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://rivoxil.com/img/48511.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://rivoxil.com/img/48511.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://rivoxil.com/img/48511.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="64px 0"
        sm-padding="40px 0"
        font="--base"
        color="--dark"
      >
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          max-width="520px"
          margin="0 auto 5px auto"
          text-align="center"
        >
          Notre offre
        </Text>
        <Text
          as="p"
          color="--grey"
          max-width="920px"
          margin="0 auto"
          text-align="center"
          font="--lead"
        >
          Chez Home Rivoxil Designs, nous nous engageons à fournir une gamme
          complète de services d'aménagement intérieur et de rénovation qui
          répondent à tous vos besoins. Que vous souhaitiez réaménager une
          simple pièce ou vous lancer dans une rénovation complète, notre équipe
          est équipée pour donner vie à votre vision avec créativité, efficacité
          et un soin personnalisé. Vous trouverez ci-dessous un aperçu de nos
          services, conçus pour transformer votre espace en un havre de beauté
          et de confort.
        </Text>
        <Box
          margin="40px -16px -16px -16px"
          display="grid"
          flex-wrap="wrap"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="32px"
          md-grid-template-columns="repeat(2, 1fr)"
          lg-grid-gap="24px"
          md-grid-gap="16px"
          sm-grid-template-columns="1fr"
          sm-grid-gap="32px"
          width="100%"
        >
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rivoxil.com/img/7.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Rénovation complète de la maison
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Consultation personnalisée : Des conseils sur mesure pour adapter
              votre espace à votre style de vie.
              <br />
              <br />
              Gestion de projet : De la conception à l'achèvement, garantissant
              un processus sans heurts et sans problèmes.
              <br />
              <br />
              Construction de qualité : L'utilisation des meilleurs matériaux et
              d'un savoir-faire artisanal pour une beauté durable.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rivoxil.com/img/10.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Conception de la cuisine et de la salle de bains
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Armoires sur mesure : Des solutions sur mesure qui allient forme
              et fonction.
              <br />
              <br />
              Rangement intelligent : Des solutions innovantes pour optimiser
              votre espace.
              <br />
              <br />
              Appareils modernes : Intégration des dernières technologies pour
              plus d'efficacité et de style.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rivoxil.com/img/8.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Rafraîchissement de l'espace de vie
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Sélection de meubles : Des choix judicieux qui reflètent votre
              style personnel.
              <br />
              <br />
              Conception de l'éclairage : Plans d'éclairage d'ambiance,
              d'appoint et d'accentuation pour mettre en valeur votre espace.
              <br />
              <br />
              Consultation sur les couleurs : Conseils d'experts sur les
              combinaisons de couleurs qui rehaussent votre humeur et votre
              décor.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rivoxil.com/img/9.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Espaces de vie extérieurs
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Conception de patios et de terrasses : Créez la toile de fond
              idéale pour vos réunions en plein air.
              <br />
              <br />
              Intégration paysagère : Mélanger harmonieusement l'intérieur et
              l'extérieur de la maison.
              <br />
              <br />
              Rénovation de l'extérieur : Améliorez l'attrait de votre maison
              grâce à des touches de design bien pensées.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 90px 0" quarkly-title="Headline-4">
        <Override slot="SectionContent" sm-min-width="280px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Text
            margin="0px 0px 0px 0px"
            width="50%"
            font="normal 500 52px/1.2 --fontFamily-serifTimes"
            padding="0px 50px 0px 0px"
            lg-width="100%"
            lg-margin="0px 0px 50px 0px"
            md-padding="0px 0 0px 0px"
            md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
            sm-margin="0px 0px 35px 0px"
          >
            Connectons-nous
          </Text>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="--lead"
              color="#727a82"
              sm-margin="0px 0px 25px 0px"
            >
              Vous souhaitez en savoir plus sur la façon dont Home Rivoxil
              Designs peut transformer votre espace ? Notre équipe est prête à
              vous guider à travers nos services et à vous aider à faire les
              meilleurs choix pour votre projet. Contactez-nous pour découvrir
              comment nous pouvons adapter nos offres à vos besoins et
              aspirations uniques. Qu'il s'agisse d'une mise à jour mineure ou
              d'une transformation majeure, nous sommes là pour transformer
              votre rêve en réalité.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
            >
              <Box
                min-width="10px"
                min-height="10px"
                display="flex"
                margin="0px 25px 0px 0px"
                sm-margin="0px 0 10px 0px"
              >
                <Text
                  margin="0px 15px 0px 0px"
                  font="--lead"
                  color="#727a82"
                  white-space="nowrap"
                >
                  Téléphone
                </Text>
                <Link
                  href="tel:+33 2 40 46 23 54"
                  color="#000000"
                  text-decoration-line="initial"
                  font="--lead"
                >
                  +33 2 40 46 23 54
                </Link>
              </Box>
              <Box
                min-width="10px"
                min-height="10px"
                display="flex"
                margin="0px 0 0px 0px"
              >
                <Text
                  margin="0px 15px 0px 0px"
                  font="--lead"
                  color="#727a82"
                  white-space="nowrap"
                >
                  E-mail
                </Text>
                <Link
                  href="mailto:info@rivoxil.com"
                  color="#000000"
                  text-decoration-line="initial"
                  font="--lead"
                >
                  info@rivoxil.com
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
